body {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  line-height: 1;
  height: 100%;
  position: relative;
  font-family: "League Gothic";
  color: #222;
}

.wrapper {
  max-width: 1280px;
  width: 90%;
  margin: 2rem auto;
  position: relative;
}

img {
  max-width: 100%;
}

header {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  background: url("../noise.2c293092.jpg") left top;
}

@media screen and (max-width: 1280px) {
  header {
    background-size: 25vw;
  }
}

.social {
  text-align: center;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  width: 100%;
  max-width: 720px;
  margin: 0 auto 3rem auto;
  justify-content: center;
  align-items: center;
}

.social li {
  display: inline-block;
  margin: 0 5px;
}

.social img {
  width: 30px;
  height: auto;
}

.social .wide {
  width: 40px;
}

.social .widee {
  width: 60px;
}

.papercut {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 22px;
}

.papercut img {
  position: absolute;
  bottom: 0;
  max-width: 1920px;
}

@media screen and (min-width: 1280px) {
  .papercut img {
    min-width: 100%;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 3;
  top: 0;
  left: 0;
  background: transparent url("../dirt.0003e788.gif") left top repeat;
}

a {
  color: #222;
}

.hidden-headline {
  visibility: hidden;
  height: 0px;
  margin: 0;
  line-height: 1;
}

h2 {
  font-size: 4rem;
  padding: 0 3rem 0 1rem;
  margin-bottom: -10px;
  letter-spacing: 0.2rem;
  background: #222;
  color: #fff;
  display: inline-block;
  clip-path: polygon(2% 0, 99% 8%, 98% 100%, 1% 93%);
  overflow: visible;
}

h1, h2, h3, h4 {
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1;
}

section {
  position: relative;
  clear: both;
}

.language-switch {
  position: absolute;
  top: 0;
  right: 0.5rem;
}

@media screen and (min-width: 800px) {
  .language-switch {
    top: 1.5rem;
    right: 2rem;
  }
}

.epkheading {
  display: block;
  margin: 0 auto 2rem;
  font-size: 1rem;
  text-align: center;
  background: #fff;
  color: #222;
}

@media screen and (min-width: 540px) {
  .epkheading {
    font-size: 2rem;
  }
}

@media screen and (min-width: 800px) {
  .epkheading {
    font-size: 4rem;
  }
}

.halves {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 800px) {
  .halves {
    grid-template-columns: 1fr 1fr;
  }
}

.merchcomposition {
  position: relative;
}

.merchcomposition a {
  width: 100%;
  display: flex;
  align-items: center;
  height: 340px;
}

.merchcomposition .logo {
  display: block;
}

.merchcomposition .background {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
}

@media screen and (min-width: 1024px) {
  .merchcomposition .background {
    opacity: 1;
  }
}

.video-container {
  position: relative;
  padding-top: 0px;
  width: 100%;
}

.video-container iframe {
  width: 100%;
  height: 100vw;
}

@media screen and (min-width: 800px) {
  .video-container iframe {
    height: 351px;
  }
}

.wide-video-container {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 800px) {
  .wide-video-container {
    grid-column: 1/span 2;
  }
}

.wide-video-container iframe {
  height: 540px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  z-index: 2;
  grid-gap: 2rem;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.grid .item {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  text-align: center;
}

.grid .item img {
  width: 100%;
  height: auto;
}

.grid .item button {
  cursor: pointer;
  margin-top: 1rem;
}

@media screen and (min-width: 800px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

button {
  padding: 1rem 1.5rem;
  border: 5px solid #222;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-family: "League Gothic";
  background: #ffffff;
}

button:hover {
  background: #222;
  color: #ffffff;
}

#tour table {
  width: 100%;
}

#tour table thead {
  text-align: left;
  background: #956200;
}

#tour table tbody tr:nth-child(2n) {
  background: #eee;
}

#tour table td, #tour table th {
  padding: 0.5rem;
}

#logos button {
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
}

.photos .item {
  position: relative;
}

.photos .item span {
  position: absolute;
  color: #ffffff;
  z-index: 2;
  margin: 1rem;
  font-size: 1rem;
  left: 0;
  bottom: 0;
}

.inline-image {
  height: 60px;
  vertical-align: middle;
  margin-left: 1rem;
}

.block-image-big {
  display: block;
  margin: 1rem auto;
}

.mono, footer, .body-text {
  font-family: "League Mono", monospace;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
}

.body-text {
  max-width: 720px;
  margin: 0 auto;
}

.prelude {
  max-width: 720px;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.centered {
  text-align: center;
}

footer {
  text-align: right;
  margin: 5rem 2rem 2rem;
}

@font-face {
  font-family: "League Gothic";
  src: url("../league_gothic.d6ed68d8.woff2") format("woff2"), url("../league_gothic.9a24e6dc.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "League Mono";
  src: url("../league_mono.12a88d71.woff2") format("woff2"), url("../league_mono.fa183fe9.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}
/*# sourceMappingURL=index.66b95a69.css.map */
