@mixin min-width($pixels){
	@media screen and (min-width: #{$pixels + 'px'}){
		@content;
	}
}

@mixin max-width($pixels){
	@media screen and (max-width: #{$pixels + 'px'}){
		@content;
	}
}

$font-color: #222;
$uglybrown: #956200;

body{
	margin: 0;
	padding: 0;
	font-size: 2rem;
	line-height: 1;
	height: 100%;
	position: relative;
	font-family: "League Gothic";
	color: $font-color;
}

.wrapper{
	max-width: 1280px;
	width: 90%;
	margin: 2rem auto;
	position: relative;
}

img{
	max-width: 100%;
}

header{
	max-width: 100%;
	position: relative;
	overflow: hidden;
	background:url('/assets/noise.jpg') left top;
	@include max-width(1280){
		background-size: 25vw;
	}
}

.social{
	text-align: center;
	list-style: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(11, 1fr);
	width: 100%;
	max-width: 720px;
	margin: 0 auto 3rem auto;
	justify-content: center;
	align-items: center;
	li{
		display: inline-block;
		margin: 0 5px;
	}
	img{
		width: 30px;
		height: auto;
	}
	.wide{
		width: 40px;
	}
	.widee{
		width: 60px;
	}
}

.papercut{
	position: absolute;
	bottom: 0;
	width: 100%;
	overflow: hidden;
	height: 22px;
	img{
		position: absolute;
		bottom: 0;
		max-width: 1920px;
		@include min-width(1280){
			min-width: 100%;
		}
	}
}

.overlay{
	width: 100%;
	height: 100%;
	position: absolute;
	pointer-events: none;
	z-index: 3;
	top: 0;
	left: 0;
	background:transparent url('/assets/dirt.gif') left top repeat;
}

a{
	color: $font-color;
}

.hidden-headline{
	visibility: hidden;
	height: 0px;
	margin: 0;
	line-height: 1;
}

h2{
	font-size: 4rem;
	padding: 0 3rem 0 1rem;
	margin-bottom: -10px;
	letter-spacing: .2rem;
	background: $font-color;
	color: #fff;
	display: inline-block;
	clip-path: polygon(2% 0, 99% 8%, 98% 100%, 1% 93%);
	overflow: visible;
}


h1,h2,h3,h4{
	font-weight: normal;
	text-transform: uppercase;
	line-height: 1;
}

section{
	position: relative;
	clear: both;
}

.language-switch{
	position: absolute;
	top: 0;
	right: .5rem;

	@include min-width(800){
		top: 1.5rem;
		right: 2rem;
	}
}

.epkheading{
	display: block;
	margin: 0 auto 2rem;
	font-size: 1rem;
	text-align: center;
	background: #fff;
	color: $font-color;
	@include min-width(540){
		font-size: 2rem;
	}
	@include min-width(800){
		font-size: 4rem;
	}
}

.halves{
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: 1fr;
	@include min-width(800){
		grid-template-columns: 1fr 1fr;
	}
}

.merchcomposition{
	position: relative;
	a{
		width: 100%;
		display: flex;
		align-items: center;
		height: 340px;
	}

	.logo{
		display: block;
	}

	.background{
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: -1;
		opacity: 0.5;
		@include min-width(1024){
			opacity: 1;
		}
	}
}

.video-container {
	position: relative;
	// overflow: hidden;
	padding-top: 0px;
	width: 100%;
	// min-height: 420px;

	iframe {
		// min-height: 420px;
		width: 100%;
		height: 100vw;

		@include min-width(800){
			height: 351px;
		}
	}
}

.wide-video-container{
	display: flex;
	flex-direction: column;
	@include min-width(800){
		grid-column: 1 / span 2;
	}
	iframe{
		height: 540px;
	}
}

.grid{
	display: grid;
	grid-template-columns: 1fr;
	z-index: 2;
	grid-gap: 2rem;
	max-width: 100%;
	position: relative;
	overflow: hidden;

	.item{
		display: flex;
		flex-direction: column;
		align-content: space-between;
		text-align: center;

		img{
			width: 100%;
			height: auto;
		}

		button{
			cursor: pointer;
			margin-top: 1rem;
		}
	}

	@include min-width(800){
		grid-template-columns: 1fr 1fr 1fr;
	}
}

button{
	padding: 1rem 1.5rem;
	border: 5px solid $font-color;
	font-size: 1.5rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	font-family: 'League Gothic';
	background: #ffffff;

	&:hover{
		background: $font-color;
		color: #ffffff;
	}
}

#tour table{
	width: 100%;
	thead{
		text-align: left;
		background: $uglybrown;
	}
	tbody tr:nth-child(2n){
		background: #eee;
	}
	td, th{
		padding: .5rem;
	}
}

#logos button{
	width: 100%;
	margin-bottom: 1rem;
	cursor: pointer;
}

.photos .item{
	position: relative;
	span{
		position: absolute;
		color: #ffffff;
		z-index: 2;
		margin: 1rem;
		font-size: 1rem;
		left: 0;
		bottom: 0;
	}
}

.inline-image{
	height: 60px;
	vertical-align: middle;
	margin-left: 1rem;
}

.block-image-big {
	display: block;
	margin: 1rem auto;
}

.mono{
	font-family: 'League Mono', monospace;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.6;
}

.body-text{
	@extend .mono;
	max-width: 720px;
	margin: 0 auto;
}
.prelude{
	max-width: 720px;
	margin: 0 auto;
	margin-bottom: 2rem;
}

.centered{
	text-align: center;
}

footer{
	@extend .mono;
	text-align: right;
	margin: 5rem 2rem 2rem;
}

@font-face {
	font-family: 'League Gothic';
	src: url('/assets/fonts/league_gothic.woff2') format('woff2'),
	url('/assets/fonts/league_gothic.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'League Mono';
	src: url('/assets/fonts/league_mono.woff2') format('woff2'),
	url('/assets/fonts/league_mono.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: auto;
}